.product-name-wrapper {
    @apply flex flex-row items-center;

    .product-name-label {
        padding-right: 6px;
    }

    .exception-icon-wrapper {
        width: 14px;
        height: 14px;
        min-width: 14px;
    }
}

.status-wrapper {
    @apply flex flex-row items-center;

    .product-name-label {}

    .exception-icon-wrapper {
        width: 14px;
        min-width: 14px;
        height: 14px;
        margin-right: 6px;
    }
}

.verification-label {
    &.verification-pending {
        @apply text-status-pending;
    }

    &.verification-valid {
        @apply text-status-valid;
    }

    &.verification-invalid {
        @apply text-status-invalid;
    }
}
@primary-color: #48E4B7;