.range-picker-container {
    @apply bg-white cursor-pointer relative flex flex-row;
    min-width: 200px;
    border: 1px solid #efefef;
    border-radius: 2px;
    transition: all 0.3s;
    &:focus,
    &:focus-visible {
        @apply border-primary;
    }
    .calendar-icon {
        @apply flex items-center pl-2 text-secondary;
    }
    .clear-cion {
        transition: color 0.3s;
        font-size: 18px;
        @apply flex items-center;
    }
    .range-dropdown-icon {
        font-size: 18px;
        @apply flex items-center pr-2;
        &.dropdown-open {
            -webkit-transform: rotateX(180deg);
            transform: rotateX(180deg);
        }
    }
    .date-value {
        @apply flex-1;
    }
    .date-placeholder,
    .date-value {
        @apply flex items-center px-2 text-secondary;
    }
    &.input-have-value {
        .date-placeholder {
            border-right: 1px solid #efefef;
            @apply bg-primary;
        }
        .calendar-icon {
            @apply bg-primary;
        }
    }
    .custom-range-picker {
        @apply h-0 w-0 overflow-hidden opacity-0 absolute right-0 bottom-0;
        &.ant-picker {
            &.ant-picker-range {
            }
        }
    }
}
.ant-picker-panel-container {
    .custom-range-picker-pannel {
        @apply flex flex-col;
        .quick-select {
            @apply flex flex-row gap-2 py-2 px-2 text-xs;
            .quick-select-title {
                padding: 6px;
                @apply flex text-center items-center text-xs font-bold;
            }
            .quick-select-item {
                padding: 6px;
                @apply px-2 flex items-center justify-center cursor-pointer;
                @apply text-secondary-light;
                transition: all 0.3s;
                border-radius: 4px;
                border: 1px solid #efefef;
                min-width: 95px;
                &:hover {
                    @apply bg-primary-light border-primary-light text-secondary;
                }
                &.active-item {
                    @apply bg-primary text-secondary;
                }
            }
        }
    }
    .ant-picker-cell {
        @apply p-1;
    }
}

@primary-color: #48E4B7;