.missions-list {
    .missions-list-table {
        table {
            .mission-action-column {
                width: 90px;
            }
            &:first-child {
                tbody {
                    display: block;
                    height: calc(50vh - 210px);
                    overflow: auto;
                }
                thead,
                tbody tr {
                    display: table;
                    width: 100%;
                    table-layout: fixed;
                }
                thead {
                    width: calc(100% - 5px);
                }
            }
        }
    }
    .table-footer {
        @apply flex flex-row bg-white items-center;
    }
}

@primary-color: #48E4B7;