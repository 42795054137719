.inventories-list {
    .inventories-list-table {
        .inventories-sub-table {
            thead {
                @apply hidden;
            }
            table {
                tbody {
                    display: block;
                    height: 100% !important;
                }
            }
        }
        table {
            tbody {
                display: block;
                overflow: auto;
            }
            thead,
            tbody tr {
                display: table;
                width: 100%;
                table-layout: fixed;
            }
            thead {
                width: calc(100% - 5px);
            }
        }
        .load-more-button {
            @apply flex flex-row items-center justify-center p-3 py-0 text-secondary-light cursor-pointer;
            border-left: 1px solid #f0f0f0;
            height: 40px;
            .loading-icon {
                @apply text-primary;
            }
            &:hover {
                transition: all 0.25s;
                background-color: #f0fff8;
            }
        }
        .product-name {
            @apply flex flex-row items-center;
        }
    }
    .table-footer {
        @apply flex flex-row bg-white items-center;
    }
}

@primary-color: #48E4B7;