@slider-transition-duration: 0.45s;
@mask-transition-duration: 0.45s;

.slide-modal-mask {
    @apply w-screen h-screen absolute top-0 left-0 overflow-hidden opacity-0 pointer-events-none;
    z-index: -100;
    background-color: #0000001c;
    transition-property: opacity, z-index;
    transition-duration: @mask-transition-duration, 0s;
    transition-delay: 0s, @mask-transition-duration;

    &.show-modal {
        transition-delay: 0s, 0s;
        @apply opacity-100 visible z-20 pointer-events-auto;
    }
}

.slide-modal {
    @apply absolute z-30 bg-white overflow-hidden pointer-events-none;
    width: 450px;
    height: calc(100vh - 30px);
    border-radius: 8px;
    right: 15px;
    top: 15px;
    box-shadow: 0px 0px 2px 0px rgb(0 0 0 / 14%);
    background-clip: padding-box;
    transform: translateX(100vw);
    transition: transform @slider-transition-duration ease;

    &.show-modal {
        @apply pointer-events-auto;
        transform: translateX(0px);
    }

    .modal-content {
        @apply flex flex-col h-full;

        .modal-header {
            @apply p-2 flex flex-row items-center;

            .modal-title-text {
                @apply font-semibold;
                font-size: 15px;
            }

            .modal-close-icon {
                @apply text-secondary-light bg-secondary-thin p-1;
                transition: all 0.35s;
                border-radius: 5px;

                &:hover {
                    box-shadow: 0px 0px 2px #00000017;
                }

                & > * {
                    @apply text-secondary-light cursor-pointer text-xl;
                    transition: color 0.35s ease;

                    &:hover {
                        @apply text-secondary;
                    }
                }
            }
        }

        .modal-footer {
        }
    }
}

.confirmation-modal {
    .confirmation-modal-content {
        @apply flex flex-col;

        .confiramtion-modal-footer {
            @apply flex flex-row;
        }
    }
}

@primary-color: #48E4B7;