.mission-dashboard {
    @apply flex flex-col h-full p-4;
    .dashboard-section {
        @apply flex flex-col;
        &.missions-table-wrapper {
            @apply flex-1;
            .missions-list-table {
                table {
                    tbody {
                        height: calc(50vh - 210px);
                    }
                }
            }
        }
        &.inventories-table-wrapper {
            @apply flex-1;
            .inventories-list {
                .inventories-list-table {
                    table {
                        tbody {
                            height: calc(50vh - 210px);
                        }
                    }
                }
            }
        }
    }
}

@primary-color: #48E4B7;