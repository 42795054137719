.slide-modal {
    &.location-history-slide-modal {
        width: 400px !important;

        .location-history-modal-content {
            @apply p-2 overflow-auto;
            height: calc(100vh - 75px);
            border-top: 1px solid #f8f8f8;

            .location-history-card {
                @apply p-2 mb-2;
                box-shadow: 0px 0px 1px rgb(0 0 0 / 30%);
                border-radius: 3px;

                .history-card-status {
                    @apply pb-2 flex flex-row items-center text-xs;

                    .status-label {
                        @apply text-secondary-light;
                    }

                    .history-status-name {
                        @apply bg-primary text-white;
                        padding: 2px 5px;
                        border-radius: 2px;
                    }

                    .history-item-icon {
                        @apply mr-1;
                        font-size: 17px;

                        &.history-change-icon {
                            @apply text-blue-700;
                        }

                        &.history-roi-icon {
                            @apply text-orange-500;
                        }

                        &.history-label-icon {
                            @apply text-primary;
                            font-size: 18px;
                        }
                    }
                }

                .history-card-header {
                    @apply flex flex-row text-xs text-secondary-light justify-between;
                    padding-bottom: 1px;
                }

                .history-card-content {
                    @apply pb-2 whitespace-pre-line;
                }

                .history-card-footer {
                    @apply flex flex-row text-xs text-secondary-light;

                    .footer-break {
                        @apply flex-1 text-center;
                    }

                    .history-footer-date {
                        @apply text-right;
                        min-width: 90px;
                    }
                }
            }
        }

        .no-records-found {
            @apply h-full flex flex-col items-center justify-center;

            .no-records-icon {
                @apply text-4xl;
            }

            .no-records-title {
                @apply text-secondary-light text-lg pb-1 pt-5;
            }

            .no-records-description {
                @apply text-secondary-light pb-14;
            }
        }

        .history-skeleton {
            @apply flex flex-col p-2 overflow-hidden;

            .history-skeleton-tile {
                @apply px-2 mb-2;
                box-shadow: 0px 0px 1px rgb(0 0 0 / 30%);
                border-radius: 3px;

                .ant-skeleton-content .ant-skeleton-title+.ant-skeleton-paragraph {
                    margin-top: 12px;
                    margin-bottom: 8px;
                }
            }
        }
    }
}
@primary-color: #48E4B7;