.profile-page {
    @apply h-full p-4;

    .profile-page-content {
        @apply flex flex-col h-full bg-white;
        box-shadow: 0px 0px 1px rgb(0 0 0 / 10%);
        border-radius: 3px;

        .profile-tab {
            .page-header {
                @apply pt-0;
            }
        }
    }
}

// general info
.general-info {
    @apply flex flex-col h-full;

    .user-info-form-wrapper {
        border: 1px solid;
        @apply flex flex-col gap-x-3 gap-y-2 p-4 self-center border-secondary-border w-full h-full;
        border-radius: 3px;

        .user-info-form {
            @apply flex flex-col gap-5;
            max-width: 800px;

            &.form-skeleton {
                @apply flex flex-col;
                .ant-skeleton {
                    &.ant-skeleton-element {
                        @apply w-full;

                        &.skeleton-input-label {
                            @apply mr-3;
                            max-width: 120px;

                            .ant-skeleton-input {
                                width: 120px;
                                min-width: 120px;
                            }
                        }

                        .ant-skeleton-input {
                            @apply w-full;
                            border-radius: 3px;
                        }
                    }
                }

                .submit-button {
                    @apply self-end;
                    width: 130px !important;
                    .ant-skeleton-input {
                        width: 130px;
                        min-width: 130px;
                    }
                    border-radius: 3px;
                }
            }

            .title-form-item {
                width: 100%;
            }

            .ant-form-item {
                @apply mb-0;
                .ant-form-item-label {
                    @apply px-1;
                    min-width: 120px;
                }
            }
        }

        .submit-button-wrapper {
            .ant-form-item-control-input {
                .ant-form-item-control-input-content {
                    @apply flex justify-end;

                    .submit-button {
                        width: 130px;
                        border-radius: 3px;
                    }
                }
            }
        }
    }
}

// END - general info

// Manage Members
.manage-members {
    @apply h-full flex flex-col;

    .search-input {
        width: 350px;
        @apply self-end justify-self-end mb-3;

        .ant-input-affix-wrapper {
            @apply border-secondary-border;
        }

        .ant-input-group-addon {
            .ant-btn {
                @apply border-secondary-border;
            }
        }
    }

    .members-list {
        border: 1px solid;
        @apply border-secondary-border shadow-none;

        .members-list-table {
            @apply flex-1;

            table {
                .mission-action-column {
                    width: 90px;
                }

                &:first-child {
                    tbody {
                        display: block;
                        height: calc(100vh - 338px);
                        overflow: auto;
                    }

                    thead,
                    tbody tr {
                        display: table;
                        width: 100%;
                        table-layout: fixed;
                    }

                    thead {
                        width: calc(100% - 5px);
                    }
                }
            }
        }

        .table-footer {
            @apply flex flex-row bg-white items-center;
        }
    }
}

// END - Manage Members

// Warehouse management
.warehouse-management {
    @apply h-full flex flex-col;

    .search-input {
        width: 350px;
        @apply self-end justify-self-end mb-3;

        .ant-input-affix-wrapper {
            @apply border-secondary-border;
        }

        .ant-input-group-addon {
            .ant-btn {
                @apply border-secondary-border;
            }
        }
    }

    .warehouse-list {
        border: 1px solid;
        @apply border-secondary-border shadow-none;

        .warehouse-list-table {
            @apply flex-1;

            table {
                .mission-action-column {
                    width: 90px;
                }

                &:first-child {
                    tbody {
                        display: block;
                        height: calc(100vh - 338px);
                        overflow: auto;
                    }

                    thead,
                    tbody tr {
                        display: table;
                        width: 100%;
                        table-layout: fixed;
                    }

                    thead {
                        width: calc(100% - 5px);
                    }
                }
            }
        }

        .table-footer {
            @apply flex flex-row bg-white items-center;
        }
    }
}

// END - Warehouse management

.tab-name {
    @apply flex flex-row items-center;

    .tab-name-icon {
        @apply pr-2 text-lg;
    }

    .tab-name-label {
    }
}

@primary-color: #48E4B7;