@timeout: 2s;

.splash-screen-bg {
    @apply absolute w-screen h-screen overflow-hidden z-50;
    background: linear-gradient(-45deg, #1b1f20, #48e4b7);
    &.fadeout-loader {
        opacity: 0;
        animation: opacity-animation @timeout;
        .splash-screen {
            -webkit-filter: blur(5px);
            -moz-filter: blur(5px);
            -o-filter: blur(5px);
            -ms-filter: blur(5px);
            filter: blur(5px);
            opacity: 0;
            animation: fadeout-loader @timeout;
        }
    }
    .splash-screen {
        @apply flex items-center justify-center w-full h-full;
        background: linear-gradient(-45deg, #1b1f20, #48e4b7);
        animation: gradient-animaton 1s ease infinite;
        &.fadeout-loader {
            -webkit-filter: blur(5px);
            -moz-filter: blur(5px);
            -o-filter: blur(5px);
            -ms-filter: blur(5px);
            filter: blur(5px);
            // opacity: 0;
            animation: fadeout-loader @timeout;
        }
        .drone-loading-indicator-wrapper {
            .drone {
                @apply fill-white;
            }
            .blade {
                fill: #8f8f8f;
                transform-origin: center;
                transform-box: fill-box;
                animation: rotate 0.5s linear infinite;
            }
            .loading-text {
                @apply text-white;
            }
        }
    }
}

@keyframes opacity-animation {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes fadeout-loader {
    0% {
        -webkit-filter: blur(infinite);
        -moz-filter: blur(infinite);
        -o-filter: blur(infinite);
        -ms-filter: blur(infinite);
        filter: blur(infinite);
        opacity: 1;
    }
    100% {
        -webkit-filter: blur(5px);
        -moz-filter: blur(5px);
        -o-filter: blur(5px);
        -ms-filter: blur(5px);
        filter: blur(5px);
        opacity: 0;
    }
}

@primary-color: #48E4B7;