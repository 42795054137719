.drone-loading-indicator-wrapper {
    // width: 300px;
    position: relative;
    height: calc(100vh - 237px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .loading-text {
        color: #dbdbdb;
        span {
            &:nth-child(1) {
                animation: ellipsis-animation-1 1.5s linear infinite;
            }
            &:nth-child(2) {
                animation: ellipsis-animation-2 1.5s linear infinite;
            }
            &:nth-child(3) {
                animation: ellipsis-animation-3 1.5s linear infinite;
            }
        }
    }
    .drone {
        // width: 200px;
        // height: 200px;
        fill: #e7e7e7;
    }
    .blade {
        fill: #dbdbdb;
        transform-origin: center;
        transform-box: fill-box;
        animation: rotate 0.5s linear infinite;
    }
    @keyframes rotate {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    @keyframes ellipsis-animation-1 {
        0% {
            opacity: 0;
        }
        33.33% {
            opacity: 1;
        }
    }
    @keyframes ellipsis-animation-2 {
        0% {
            opacity: 0;
        }
        33.33% {
            opacity: 0;
        }
        66.66% {
            opacity: 1;
        }
    }
    @keyframes ellipsis-animation-3 {
        0% {
            opacity: 0;
        }
        66.66% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
}

.table-skeleton-container {
    @apply flex flex-col gap-2 p-2;
    display: flex !important;
    overflow: hidden !important;
    .table-skeleton-row {
        @apply flex flex-row gap-1;
        & > div {
            span {
                @apply min-w-0 w-full;
                height: 42px;
                border-radius: 3px;
            }
            &:nth-child(1) {
                @apply flex-1;
            }
            // &:nth-child(2) {
            //     @apply w-24;
            // }
            // &:nth-child(3) {
            //     @apply flex-1;
            // }
            // &:nth-child(4) {
            //     @apply w-8;
            // }
        }
    }
}

@primary-color: #48E4B7;