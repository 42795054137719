.action-menu-icon-wrapper {
    @apply w-full flex cursor-pointer items-center justify-center;

    .action-menu-icon {
        transition: color 0.25s ease;

        &:hover {
            @apply text-primary;
        }
    }
}

.action-items {
    @apply flex flex-col;

    .action-item {
        min-width: 125px;
        font-size: 13.5px;
        padding-top: 0.6rem;
        padding-bottom: 0.6rem;
        @apply flex flex-row cursor-pointer px-3 text-secondary-light items-center;
        &.disabled {
            @apply opacity-30 pointer-events-none;
        }

        .action-item-icon {
            @apply pr-2;
            font-size: 18px;
        }

        transition: all 0.25s ease;

        &:hover {
            @apply bg-primary-thin;
        }
    }
}

@primary-color: #48E4B7;