.map-view {
    transition: all 0.45s ease;
    height: calc(50vh - 103px);
    @apply flex-1 w-full h-full overflow-hidden max-w-0 bg-white p-0;
    border-radius: 3px;

    &.show-map {
        @apply max-w-full p-2;
        box-shadow: 0px 0px 1px rgb(0 0 0 / 10%);

        .map-content {
            @apply p-2;
        }
    }

    .map-content {
        @apply h-full w-full bg-secondary-thin p-0 overflow-hidden;
        @apply relative;
        transition: all 0.45s ease;
        border-radius: 3px;

        .map-preview {
            .map-mask {
                @apply absolute left-0 top-0 w-full h-full z-10 flex items-center justify-center;

                div {
                    color: #000000c7;
                }

                background-color: #0000000d;
            }

            @apply w-full flex flex-col gap-2;
            height: calc(50vh - 138px);

            .map-preview-row {
                @apply flex flex-row gap-2;

                .map-bin {
                    @apply relative overflow-hidden;
                    background-color: #f1f1f1;
                    border-radius: 3px;
                    min-width: 80px;
                    width: 80px;
                    aspect-ratio: 3/2;

                    img {
                        opacity: 0.3;
                    }

                    .bin-bar {
                        @apply absolute bottom-0 left-0 w-full;
                        height: 12px;
                        background-color: #48e4b845;
                    }
                }
            }
        }
    }
}
@primary-color: #48E4B7;