.app-header {
    border-bottom: 1px solid;
    @apply flex flex-row border-secondary-border;
    height: 55px;
    box-shadow: 1px 1px 2px rgb(0 0 0 / 8%);

    .current-history-tabs {
        @apply h-full;
        border-right: 1px solid #efefef;
    }

    .search-input {
        @apply flex-1 flex justify-center px-1;

        input {
            @apply px-1;
        }

        .search-icon {
            font-size: 25px;
            @apply text-secondary-light;
        }
    }

    .header-actions {
        @apply h-full;

        .action-icon {
            @apply h-full flex items-center justify-center text-secondary-light cursor-pointer;
            transition: all 0.25s;
            border-left: 1px solid #efefef;
            width: 75px;
            font-size: 25px;

            &:hover {
                @apply text-secondary;
            }
        }
    }
}

.page-header {
    @apply flex flex-row py-3;
    .page-title-wrapper {
        @apply flex flex-col;
        .page-title {
            @apply font-bold text-xl;
        }
        .page-sub-title {
            @apply font-thin text-sm text-secondary-light py-1;
        }
    }

    .header-spacer {
        @apply flex-1;
    }

    .header-actions {
        @apply self-end flex flex-row gap-4;
    }
}

@primary-color: #48E4B7;