@left-section-width: 650px;

.location-details-container {
    .image-previewer-section {
        @apply flex flex-col border-secondary-border;

        .image-preview-legends {
            @apply inline-flex flex-row items-center pt-2;

            .lengend-wrapper {
                @apply inline-flex flex-row items-center;

                .legend-label {
                    @apply pl-2 pr-8 text-secondary-light;
                }

                .legend-icon {
                    @apply inline-block w-3 h-3;
                    border-radius: 2px;

                    &.roi-legend {
                        @apply bg-legend-roi;
                    }

                    &.product-legend {
                        @apply bg-legend-product;
                    }

                    &.code-legend {
                        @apply bg-legend-code;
                    }
                }
            }
        }
    }

    .product-list-section {
        &.table-container-wrapper {
            box-shadow: unset;
            border-radius: unset;

            .ant-table-wrapper {
                height: calc(100% - 52px);

                &>div {
                    height: 100%;

                    &>div {
                        height: 100%;

                        &>div {
                            height: 100%;

                            &>div {
                                height: 100%;

                                &>div {
                                    height: 100%;

                                    table {
                                        @apply block h-full;

                                        tbody {
                                            max-height: calc(100% - 52px);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &.products-list {
            .ant-table {
                .ant-table-thead {
                    @apply bg-secondary-thin01 relative;

                    tr {
                        th {
                            background-color: inherit;
                        }
                    }

                    &::after {
                        @apply block absolute top-0 bg-secondary-thin01 h-full;
                        content: '';
                        width: 5px;
                        right: -5px;
                    }
                }

                tbody {
                    display: block;
                    overflow: auto;
                    height: calc(100vh - 302px);

                    .ant-table-cell {
                        padding: 8px 16px;
                    }
                }

                thead,
                tbody tr {
                    display: table;
                    width: 100%;
                    table-layout: fixed;
                }

                thead {
                    width: calc(100% - 5px);
                }
            }
        }

        .table-footer {
            @apply flex flex-row bg-white items-center;
        }
    }

    .product-details-section {
        @apply border-secondary-border;

        .product-details-header {
            @apply px-4 py-2 border-secondary-border bg-secondary-thin01;
            border-bottom-width: 1px;
        }

        .product-details-content {
            @apply grid px-4 py-2 border-secondary-border overflow-auto;
            max-height: calc(100vh - 702px);
            grid-template-columns: 1fr 1fr;
            column-gap: 30px;

            .product-details-item {
                @apply flex flex-row items-start pt-2 pb-1;

                .data-label {
                    @apply pr-2 pl-1 text-secondary-light01 whitespace-nowrap text-ellipsis;
                    width: 40%;

                    &:first-letter {
                        text-transform: uppercase;
                    }
                }

                .data-value {
                    @apply pl-2 pr-1 text-secondary-light;
                    width: 60%;
                }
            }
        }

        .product-details-empty-tip {
            @apply text-secondary-light01 px-2 py-4 mt-4 text-center;
        }
    }

    .case-count-section {
        @apply border-secondary-border;
        border-bottom-width: 1px;

        .case-count-header {
            @apply px-4 py-2 border-secondary-border bg-secondary-thin01;
            border-bottom-width: 1px;
        }

        .case-count-content {
            @apply grid px-4 pt-2 pb-4 border-secondary-border overflow-auto;
            grid-template-columns: 1fr 1fr;
            column-gap: 30px;

            .case-count-item {
                @apply flex flex-row items-start pt-2 pb-1 justify-between;

                .data-label {
                    @apply pr-2 pl-1 text-secondary-light01 whitespace-nowrap text-ellipsis;
                }

                .data-value {
                    @apply pl-2 pr-1 text-secondary-light;
                }
            }
        }
    }

    .location-details-header {
        grid-area: location-details-header;
        @apply flex flex-row px-4 py-2 bg-white border-secondary-border;
        border-bottom-width: 1px;

        .location-mission-details {
            @apply flex flex-row py-1 w-full items-center;

            &>* {
                @apply px-2;

                .mission-details-label {
                    @apply text-secondary-light01 pr-2;
                }

                .mission-details-value {
                    @apply text-secondary-light;
                }
            }
        }

        .add-new-product-button {
            @apply inline-flex flex-row items-center text-secondary pl-2 pr-3 ml-2;
            text-shadow: none;

            &:hover {
                @apply text-white;
            }

            .add-new-product-icon {
                @apply inline mr-1;
                font-size: 20px;
            }
        }
    }

    .location-details-slide-modal {
        .location-details-modal-content {
            @apply grid px-2 gap-2;
            height: calc(100vh - 82px);
            grid-template-areas:
                'image-previewer-section'
                'product-list-section';
        }

        .modal-header {
            @apply flex items-center;
        }

        .location-details-slider-header-prefix {
            @apply font-semibold;
            font-size: 15px;

            .location-name {
                @apply text-primary pl-2;
            }
        }

        .location-details-slider-header-postfix {
            @apply flex items-center pr-1;
        }

        width: 520px;

        .image-previewer-section {
            @apply p-1;
            box-shadow: 0px 0px 1px rgb(0 0 0 / 30%);
            border-radius: 3px;

            .ant-skeleton {
                @apply w-full h-full;
                aspect-ratio: 3/2;

                .ant-skeleton-input {
                    @apply w-full h-full;
                    aspect-ratio: 3/2;
                    border-radius: 3px;
                }
            }
        }

        .product-list-section {
            @apply flex-1;
            box-shadow: 0px 0px 1px rgb(0 0 0 / 30%);
        }

        .view-products-button {
            @apply flex items-center justify-center w-full bg-primary text-secondary cursor-pointer p-2 uppercase;
            transition: all 0.25s;
            border-radius: 3px;

            &:hover {
                @apply text-white;
                transform: translateY(-1px);
                box-shadow: 0px 0px 5px rgb(46 229 157 / 40%);
            }
        }
    }

    .location-details-page-content {
        @apply flex flex-col;
        height: calc(100vh - 55px);

        .location-details-breadcrumb {
            .bin-navigation-wrapper {
                @apply flex flex-row px-4 items-center gap-2;

                .bin-navigation-button {
                    @apply bg-primary text-secondary;
                    padding: 3.5px;
                    border-radius: 2px;
                    transition: all 0.25s;

                    &:hover {
                        @apply text-white;
                    }
                }
            }

            .location-history-icon-wrapper {
                border-right: 1px solid;
                @apply h-full flex items-center border-secondary-border;

                .location-history-icon {
                    @apply text-secondary-light cursor-pointer;
                    transition: color 0.25s;

                    &:hover {
                        @apply text-primary;
                    }
                }
            }
        }

        .location-details-content-wrapper {
            @apply grid w-full flex-1 p-4;

            .location-details-content {
                @apply grid w-full h-full bg-white;
                overflow: hidden;
                box-shadow: 0px 0px 1px rgb(0 0 0 / 10%);
                border-radius: 3px;
                height: calc(100vh - 138px);

                grid-template-areas:
                    'location-details-header'
                    'location-details-flex-container';
                grid-template-rows: auto 1fr;
                grid-template-columns: 1fr;

                &>* {
                    min-height: 0;
                }

                .location-details-flex-container {
                    @apply flex flex-row;
                    grid-area: location-details-flex-container;

                    .left-section {
                        @apply flex flex-col;
                        width: @left-section-width;

                        .image-previewer-section {
                            @apply p-4 pb-2;
                            border-right-width: 1px;
                            // width: calc(@left-section-width - 2rem);

                            .image-skeleton-wrapper {
                                @apply flex flex-row;

                                .image-preview-thumbnail {
                                    &.ant-skeleton {
                                        @apply w-full h-full flex-1;
                                        aspect-ratio: 3/2;

                                        .ant-skeleton-input {
                                            @apply w-full h-full;
                                            aspect-ratio: 3/2;
                                            border-radius: 3px;
                                        }
                                    }
                                }

                                .thumbnail-container-scroll {
                                    @apply overflow-hidden;
                                    min-width: 100px;
                                    max-height: 342px;
                                    height: 342px;

                                    .skeleton-thumbnail-wrapper {
                                        @apply flex flex-col;
                                        padding: 0px 5px;

                                        .thumbnail-skeleton {
                                            &.ant-skeleton {
                                                // width: unset;
                                            }

                                            &:first-child {
                                                margin-top: 0px;
                                            }

                                            @apply flex flex-col overflow-hidden;
                                            width: 92px;
                                            min-height: 58.656px;
                                            height: 58.656px;
                                            min-width: 92px;
                                            border: 4px solid transparent;
                                            border-radius: 3px;
                                            margin-top: 2px;
                                        }
                                    }
                                }
                            }

                            border-bottom-width: 1px;
                        }

                        .product-details-section {
                            @apply flex-1;
                            border-right-width: 1px;
                        }
                    }

                    .right-section {
                        @apply flex flex-col flex-1;

                        .product-list-section {
                            @apply flex-1;
                        }
                    }
                }
            }
        }

        .product-list-section {
            .quantity-cell {
                &.quantity-min {
                    width: 120px;
                }
            }
        }
    }

    .location-history {
        @apply flex items-center border-r-0;
    }
}

.image-gallery {
    @apply overflow-hidden;
    border-radius: 5px;

    .image-gallery-content {
        .image-gallery-thumbnails-wrapper {
            .image-gallery-thumbnails {
                .image-gallery-thumbnails-container {
                    height: 100%;
                    overflow: auto;
                    scrollbar-width: none;

                    &::-webkit-scrollbar {
                        display: none;
                    }

                    .image-gallery-thumbnail {
                        @apply overflow-hidden;
                        border-radius: 2px;
                        aspect-ratio: 3/2;
                        transition: all 0.25s;

                        .error-thumbnail {
                            @apply h-full w-full;

                            &.ant-skeleton-image {
                                .ant-skeleton-image-svg {
                                    width: 28px;
                                }
                            }
                        }

                        &.active {
                            @apply border-primary;
                            border-width: 3px;
                        }

                        &:hover {
                            border: 3px solid #51e4b88e;
                        }
                    }
                }
            }
        }

        .image-gallery-slide-wrapper {
            .image-previewer-custom-controls {
                @apply absolute bottom-3 right-3 flex flex-row gap-2;
                z-index: 4;

                .control-button {
                    @apply p-1 bg-white text-secondary cursor-pointer;
                    transition: all 0.25s;
                    border-radius: 2px;
                    font-size: 20px;
                    box-shadow: rgb(0 0 0 / 16%) 0px 1px 2px;

                    &.active {
                        @apply bg-primary text-white;
                    }
                }
            }

            .image-gallery-bullets {
                bottom: 15px;

                .image-gallery-bullets-container {
                    .image-gallery-bullet {
                        padding: 2px;

                        &:hover {
                            @apply bg-primary border-primary;
                        }
                    }
                }
            }

            .raphael-canvas-image {
                &.canvas-error-image {
                    width: 800px !important;
                    height: 700px !important;
                    transform: translate(calc(50% - 400px), calc(50% - 350px));
                }
            }
        }

        .image-gallery-icon {

            &.image-gallery-right-nav,
            &.image-gallery-left-nav {
                padding: 10px 10px;

                .image-gallery-svg {
                    width: 25px;
                    height: 35px;
                }
            }

            &.image-gallery-fullscreen-button {
                padding: 20px 15px;

                .image-gallery-svg {
                    width: 20px;
                    height: 20px;
                }
            }
        }

        // .image-gallery-slide-wrapper {
        //     width: 100%;
        // }

        .custom-image-wrapper {

            // width: 2736px;
            // width: 1824px;
            .react-transform-wrapper {
                @apply overflow-hidden w-full h-full;

                .react-transform-component {
                    @apply w-full h-full;

                    .image-canvas {
                        @apply w-full;
                        transform-origin: top left;

                        .canvas-with-empty-image {
                            background-color: #f2f2f2;
                        }
                    }
                }
            }
        }
    }

    &.have-thumbnail {
        .image-gallery-content {
            .image-gallery-slide-wrapper {
                width: calc(100% - 110px);
            }
        }

        &.full-screen {
            .image-gallery-content {
                .image-gallery-slide-wrapper {
                    width: calc(100% - 110px);
                }
            }
        }
    }

    .image-gallery-content {
        .image-gallery-slide-wrapper {
            width: 100%;
        }
    }
}

.brightness-control-popover {
    @apply bg-white p-1 flex items-center justify-center overflow-hidden;
    width: 35px;
    height: 150px;
    border-radius: 10px;

    .ant-slider {
        &.ant-slider-vertical {
            @apply m-0 my-2;
            height: 125px;
        }
    }
}

.add-new-product-modal {
    @apply bg-white p-4 pointer-events-auto;
    border-radius: 5px;

    .add-product-form {
        @apply flex flex-col;
        .add-product-header {
            @apply text-lg font-bold pb-4;
        }

        .add-product-footer {
            @apply flex flex-row justify-end;
            & > * {
                @apply ml-2;
            }
        }

        .add-product-content {}
    }
}
@primary-color: #48E4B7;