.app {
    @apply grid h-screen w-screen bg-white;
    grid-template-areas: 'side-bar app-content-wrapper';
    .side-bar {
        grid-area: side-bar;
    }
    .side-bar {
        & + .app-content-wrapper {
            width: calc(100vw - 225px);
        }
        &.collapse {
            & + .app-content-wrapper {
                width: calc(100vw - 68px);
            }
        }
    }
    .app-content-wrapper {
        @apply flex flex-col;
        transition: all 0.35s ease;
        grid-area: app-content-wrapper;
        .app-content {
            @apply bg-secondary-thin flex-1;
        }
    }
    font-family: 'Euclid Flex';
    * {
        font-family: 'Euclid Flex';
    }
}

* {
    /* scrollbar */
    ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgb(164 164 164 / 30%);
        -webkit-border-radius: 10px;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgb(164 164 164 / 30%);
        -webkit-border-radius: 10px;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
        -webkit-border-radius: 10px;
        border-radius: 10px;
        background: rgba(255, 255, 255, 0.3);
        -webkit-box-shadow: inset 0 0 6px rgb(112 112 112 / 50%);
    }

    ::-webkit-scrollbar-thumb:window-inactive {
        background: rgba(255, 255, 255, 0.3);
    }
}

@primary-color: #48E4B7;