@tailwind base;
@tailwind components;
@tailwind utilities;
@import '~antd/dist/antd.less';
@import 'react-image-gallery/styles/css/image-gallery.css';

@import '../assets/fonts/font.css';

@import './global-styles.less';

@primary-color: #48E4B7;