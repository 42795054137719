.app-breadcrumb {
    border-bottom: 1px solid #EFEFEF;
    @apply flex flex-row bg-white border-secondary-border items-center;

    &>* {
        border-right: 1px solid #EFEFEF;
        @apply px-4 border-secondary-border;

        &:last-child {
            border-right: 0px;
        }
    }

    .breadcrumb-link-wrapper {
        border-right: 1px solid #EFEFEF;
        @apply p-3 border-secondary-border;

        .app-antd-breadcrumb {
            .breadcrumb-separator-icon {
                @apply inline;
                margin-bottom: 2px;
            }
        }
    }

    .breadcrumb-content {
        @apply flex-1 h-full;
    }

    .breadcrumb-actions {
        @apply flex flex-row;
    }
}
@primary-color: #48E4B7;