.popover-list {
    @apply flex flex-col p-0;

    .popover-list-option {
        @apply flex flex-row text-secondary-light cursor-pointer overflow-hidden;
        transition: all 0.25s ease;
        border-radius: 3px;

        &:hover {
            @apply bg-primary-thin;
        }

        .option-icon {
            @apply pl-3 py-2;
        }

        .option-label {
            @apply px-3 py-2;
            font-size: 13.5px;
        }
    }
}

@primary-color: #48E4B7;