html,
body {
    @apply m-0 p-0 overflow-hidden;
}

.ant-table-wrapper {
    .ant-table {
        colgroup {
            // when enabling row selection, colgroup breaks the table, so hided the colgroup
            display: none;
        }

        .ant-table-selection-column {
            // hiding checkbox in table rows
            display: none;
        }

        .ant-table-placeholder {
            @apply h-full;
        }

        .ant-table-thead {
            tr {
                th {
                    @apply bg-white;
                    padding: 14px 16px;

                    &.action-item-cell {
                        padding: 14px 0px !important;
                    }
                }
            }
        }

        .action-item-cell {
            font-size: 19px;
            width: 30px;
            padding: 12px 0px !important;
            padding-right: 7px !important;
            overflow: visible;
        }

        .quantity-cell {
            width: 110px;
            &.quantity-min {
                width: 80px;
            }
        }

        .ant-table-row {
            @apply cursor-pointer;

            &.ant-table-row-selected {
                td {
                    &:first-child {
                        @apply relative;

                        &::before {
                            @apply block absolute left-0 top-0 h-full;
                            content: '';
                            width: 3px;
                            border-radius: 3px 0px 0px 3px;
                            @apply bg-primary;
                        }
                    }

                    &.ant-table-selection-column {
                        &::before {
                            @apply hidden;
                        }

                        &+ :nth-child(2) {
                            @apply relative;

                            &::before {
                                @apply block absolute left-0 top-0 h-full;
                                content: '';
                                width: 3px;
                                border-radius: 3px 0px 0px 3px;
                                @apply bg-primary;
                            }
                        }
                    }
                }
            }
        }

        .ant-table-expanded-row {
            &>.ant-table-cell {
                @apply p-0 bg-white;

                &::before {
                    @apply absolute left-0 top-0 block h-full bg-primary-light z-10;
                    content: '';
                    width: 3px;
                    border-radius: 3px 0px 0px 3px;
                }

                padding-left: 48px;

                .ant-table-content {
                    .ant-table-tbody {
                        background-color: #fbfbfb;

                        .ant-table-row {
                            @apply border-transparent;
                            border-left: 1px solid #f0f0f0;

                            & td {
                                &:first-child {}
                            }

                            .ant-table-cell {
                                &.ant-table-selection-column {
                                    @apply bg-primary-thin;
                                }
                            }
                        }
                    }
                }
            }
        }

        .ant-table-row-expand-icon-cell {
            width: 49px;
        }

        .ant-table-cell {
            @apply text-secondary-light;
            padding: 12px 16px;
        }
    }
}

.table-container-wrapper {
    @apply overflow-hidden;
    box-shadow: 0px 0px 1px rgb(0 0 0 / 10%);
    border-radius: 3px;
}

.ant-pagination {
    @apply bg-white m-0 py-3 px-3;

    .ant-pagination-item-link {
        .anticon {
            margin-bottom: -1px;
            display: block;
        }
    }
}

.react-select {
    @apply cursor-pointer;

    &>div {
        cursor: pointer !important;
    }

    border: 1px solid #efefef;
    border-radius: 2px;

    &>div {
        min-height: 32px;

        &:nth-of-type(2) {
            z-index: 9;
        }
    }

    .react-select-selected-option {
        background-color: #48e4b752 !important;
    }

    .value-container {
        @apply border-r-0;

        .select-placeholder {
            @apply px-2 text-secondary;
        }

        .select-value {
            @apply px-2 text-secondary;
        }

        .icon-wrapper {
            @apply pl-2 text-secondary;
            font-size: 17px;
        }
    }

    .dropdown-indicator {
        @apply pl-0;

        &.animated-dropdown-indicator {
            &.rotate-icon-180 {
                -webkit-transform: rotateX(180deg);
                transform: rotateX(180deg);
            }

            transition: all 0.3s !important;
        }
    }

    .clear-indicator {
        @apply px-0;
    }
}

.ant-picker {
    &.ant-picker-range {
        padding: 5px 11px 5px;
        border: 1px solid #efefef;

        &.ant-picker-focused {
            box-shadow: none;
        }
    }
}

.dropdown-icon {
    transition: all 0.3s;
    width: 20px;
    height: 20px;
    color: hsl(0, 0%, 80%);
    fill: hsl(0, 0%, 80%);
    cursor: pointer;

    &.clear-icon {
        padding: 3px;
    }

    &:hover {
        color: hsl(0, 0%, 60%);
        fill: hsl(0, 0%, 60%);
    }

    &.icon-focused {
        color: #545454;
        fill: #545454;
    }
}

.app-input-container {
    height: 34px !important;
    min-height: 34px !important;
}

.title-text {
    @apply font-bold text-xl;
}

.search-mark {
    @apply bg-primary-light;
}
mark {
    padding: 1px;
}

.ant-input {
    &.ant-input-disabled {
        @apply bg-white;
    }
}

.ant-input-wrapper {
    &.ant-input-group {
        .ant-input {
            border-right: 0px;
            border-color: #d9d9d966;
        }

        .ant-input-group-addon {
            @apply text-primary p-0;
            background-color: white;
            border-color: #d9d9d966;
            padding-right: 7px;
        }
    }
}

.ant-popover {
    .ant-popover-content {
        .ant-popover-arrow {
            right: 0px;
        }

        .ant-popover-inner-content {
            @apply p-0;
        }
    }
}

.ant-switch {
    transform: scale(0.85);
}

.ant-tooltip {
    @apply z-10;

    .ant-tooltip-content {
        .ant-tooltip-inner {
            @apply text-secondary-light text-center;
            font-size: 13px;
            min-height: 28px;
        }
    }
}

.disabled-button {
    @apply select-none;
    pointer-events: none !important;
    cursor: not-allowed !important;
    background-color: #f2f2f2 !important;
    color: rgba(0, 0, 0, 0.26) !important;

    &.active {
        background-color: #d9faf2 !important;
    }
}

// ant tabs
.ant-tabs {
    @apply h-full;

    .ant-tabs-content-holder {
        .ant-tabs-content {
            @apply h-full;

            .ant-tabs-tabpane {
                @apply p-4;
            }
        }
    }

    .ant-tabs-nav-wrap {
        .ant-tabs-nav-list {
            .ant-tabs-tab {
                @apply px-4 py-4 m-0 mt-0 relative;
                border-bottom: 1px solid;
                @apply border-secondary-border;

                &::before {
                    @apply block absolute right-0 top-0 bg-primary;
                    content: '';
                    width: 0px;
                    height: 100%;
                    // border-radius: 4px 0px 0px 4px;
                }

                &.ant-tabs-tab-active {
                    @apply bg-secondary-thin;

                    &::before {
                        transition: width 0.25s;
                        width: 3px;
                    }
                }
            }
        }
    }

    .ant-tabs-content-holder {
        @apply p-0;
    }
}

// END - ant tabs

.ant-modal-confirm {
    @apply overflow-hidden;
    border-radius: 5px;
}