@height: calc(100vh - 145px);

.insights {
    @apply flex flex-col h-full p-4 overflow-hidden;
    max-height: calc(100vh - 55px);

    .insights-charts {
        @apply block overflow-auto;
        height: @height;

        iframe {
            width: 1px;
            min-width: 100%;
        }

        &.insights-loading {
            @apply h-0 w-0;
        }

        &.insights-skeleton-container {
            @apply flex flex-col gap-4 overflow-hidden;
            height: @height;

            .header-skeleton {
                @apply w-full;
                height: 60px;
                border-radius: 3px;
            }

            .trends-skeleton-wrapper {
                @apply flex flex-row gap-4;
                border-radius: 3px;

                .trends-skeleton {
                    @apply flex-1 w-full h-full aspect-square;
                    border-radius: 3px;
                }
            }

            .insights-skeleton-wrapper {
                @apply flex flex-col gap-4;
                border-radius: 3px;

                .insights-skeleton {
                    @apply flex-1 w-full;
                    min-height: 400px;
                    border-radius: 3px;
                }
            }
        }
    }
}
@primary-color: #48E4B7;