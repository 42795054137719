.drone-list {
    .drone-list-table {
        table {
            .mission-action-column {
                width: 90px;
            }

            &:first-child {
                tbody {
                    display: block;
                    height: calc(100vh - 252px);
                    overflow: auto;
                }

                thead,
                tbody tr {
                    display: table;
                    width: 100%;
                    table-layout: fixed;
                }

                thead {
                    width: calc(100% - 5px);
                }
            }
        }
    }

    .table-footer {
        @apply flex flex-row bg-white items-center;
        .expiry-filter-wrapper {
            @apply flex flex-row items-center px-3;
            .expiry-label {
                @apply text-secondary-light pr-3;
            }
            .expiry-filter {
                @apply min-w-max w-auto;
                max-width: 200px;
                width: 200px;
            }
        }
    }
}

@primary-color: #48E4B7;