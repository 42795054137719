.download-button {
    @apply text-primary p-2 cursor-pointer;
    &.loading {
        @apply pointer-events-none opacity-50 cursor-not-allowed;
        .download-icon-wrapper {
            min-width: 24px;
            @apply text-base;
        }
    }
    @apply flex flex-row text-center items-center;
    .download-icon-wrapper {
        @apply text-xl pr-1 flex items-center justify-center;
    }
}

@primary-color: #48E4B7;