@font-face {
    font-family: 'Euclid Flex';
    src: url('./EuclidFlex-Gather-UltraLight.ttf');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Euclid Flex';
    src: url('./EuclidFlex-Gather-Light.ttf');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Euclid Flex';
    src: url('./EuclidFlex-Gather-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Euclid Flex';
    src: url('./EuclidFlex-Gather-SemiBold.ttf');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Euclid Flex';
    src: url('./EuclidFlex-Gather-Bold.ttf');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Euclid Flex';
    src: url('./EuclidFlex-Gather-Thin.ttf');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Euclid Flex';
    src: url('./EuclidFlex-Gather-Medium.ttf');
    font-weight: 500;
    font-style: normal;
}

@primary-color: #48E4B7;