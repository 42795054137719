.search-screen {
    @apply flex flex-col h-full p-4;
    .inventories-list {
        .inventories-list-table {
            table {
                tbody {
                    height: calc(100vh - 252px);
                }
            }
        }
    }
}

@primary-color: #48E4B7;