@sidebar-width: 225px;
@sidebar-width-collapsed: 68px;

.side-bar {
    @apply h-full flex flex-col relative top-0;
    width: @sidebar-width;
    transition: all 0.35s ease;
    box-shadow: 1px 1px 2px rgb(0 0 0 / 8%);
    .logo-wrapper {
        height: 50px;
        width: @sidebar-width;
        @apply px-5 py-3 overflow-hidden flex items-center;
        .logo {
            min-width: 24px;
            height: 24px;
        }
    }
    &:hover {
        .expand-collapse-icon {
            @apply opacity-100;
        }
    }
    &.collapse {
        .logo-wrapper {
            height: 50px;
            width: @sidebar-width-collapsed;
        }
        width: @sidebar-width-collapsed;
        .option-list {
            .menu-item {
                .menu-item-link {
                    @apply cursor-default;
                    .menu-item-title {
                        @apply opacity-0;
                    }
                    .menu-item-icon-wrapper {
                        @apply cursor-pointer;
                        .tooltip {
                            display: block;
                        }
                    }
                }
            }
            .active {
                .menu-item {
                    .menu-item-link {
                        .menu-item-icon-wrapper {
                            @apply text-primary bg-secondary-thin;
                        }
                    }
                }
            }
        }
    }
    .option-list {
        .active {
            @apply bg-secondary-thin;
            &.menu-item {
                &::after {
                    transition: 0.25s ease;
                    width: 4px;
                    @apply bg-primary;
                }
                .menu-item-link {
                    @apply text-primary bg-secondary-thin;
                    &:hover {
                        @apply text-primary bg-secondary-thin;
                    }
                }
            }
        }
        .menu-item {
            @apply px-3 py-1 my-2 relative;
            &::after {
                @apply block absolute right-0 top-0;
                content: '';
                width: 0px;
                height: 100%;
                border-radius: 4px 0px 0px 4px;
                @apply bg-primary;
            }
            .menu-item-link {
                @apply flex items-center cursor-pointer text-secondary-light px-2 py-1;
                transition: 0.5s ease;
                &:hover {
                    @apply text-black bg-secondary-thin;
                    border-radius: 4px;
                }
                .menu-item-icon-wrapper {
                    @apply relative;
                    .menu-item-icon {
                        transition: 0.5s ease;
                        font-size: 32px;
                        min-width: 35px;
                        padding: 5px 10px 5px 0px;
                        &:hover {
                            @apply text-black;
                        }
                    }

                    .tooltip {
                        @apply absolute hidden opacity-0 whitespace-nowrap pointer-events-none bg-white z-10;
                        top: -2.1rem;
                        left: calc(@sidebar-width-collapsed - 10px);
                        border-radius: 0.4rem;
                        padding: 10px;
                        transition: 0s;
                        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
                    }

                    &:hover {
                        color: var(--white-color);
                        transition: 0.5s ease;
                        .tooltip {
                            @apply opacity-100;
                            top: 50%;
                            transform: translateY(-50%);
                            transition: all 0.5s ease;
                        }
                    }
                }
                .menu-item-title {
                    transition: 0.5s ease;
                    @apply whitespace-nowrap overflow-hidden;
                }
            }
        }
    }
    .expand-collapse-icon {
        @apply absolute flex right-0 items-center justify-center cursor-pointer opacity-0 bg-white z-20;
        top: 46px;
        width: 23px;
        height: 23px;
        padding: 7px;
        border-radius: 23px;
        transform: translate(50%, 0px) rotate(90deg);
        transition: background-color 100ms linear 0s, color 100ms linear 0s, opacity 350ms cubic-bezier(0.2, 0, 0, 1) 0s;
        box-shadow: rgb(9 30 66 / 8%) 0px 0px 0px 1px, rgb(9 30 66 / 8%) 0px 2px 4px 1px;
        color: rgb(107, 119, 140);
        &:hover {
            @apply bg-primary fill-white;
        }
        .chevron {
            display: inline-block;
            vertical-align: middle;
            width: 18px;
            height: 15px;

            &__group {
                transform: translateY(0);
                transition: transform 0.35s linear;
            }

            &__box--left,
            &__box--right {
                transform: rotate(0) translateY(0);
                transition: transform 0.35s linear;
            }

            &__box--left {
                transform-origin: 1px 1px;
            }

            &__box--right {
                transform-origin: 7px 1px;
            }

            &.__flip {
                .chevron__box--left {
                    transform: rotate(-90deg) translateY(0);
                }
            }

            &.__flip {
                .chevron__box--right {
                    transform: rotate(90deg) translateY(0);
                }
            }

            &.__flip {
                .chevron__group {
                    transform: translateY(3px);
                }
            }
        }
    }
}

@primary-color: #48E4B7;