.custom-tabs {
    @apply relative flex flex-row h-full;
    .tab {
        @apply px-3 py-2 text-secondary-light text-center w-full cursor-pointer flex items-center justify-center;
        min-width: 90px;
        &.active {
            @apply text-secondary;
        }
    }
    .active-tab-indicator {
        transition: all 0.3s;
        @apply absolute bg-primary bottom-0;
        border-radius: 10px;
        height: 2.5px;
    }
}

@primary-color: #48E4B7;