.react-select {
    &.react-multi-select {
        .value-container {
            .value-wrapper {
                @apply flex flex-row items-center;
            }
        }
        .react-multi-select-option {
            @apply py-2;
            transition: background-color 0.25s ease;
            .select-item-label {
                @apply text-secondary-light pl-1;
            }
            &.react-select-selected-option {
                .select-item-label {
                    @apply text-secondary;
                }
            }
        }
    }
    &.react-single-select {
        .react-single-select-option {
            @apply py-2;
            transition: background-color 0.25s ease;
            .select-item-label {
                @apply text-secondary-light pl-1;
            }
            &.react-select-selected-option {
                .select-item-label {
                    @apply text-secondary;
                }
            }
        }
        .value-container {
            @apply flex flex-row items-stretch self-stretch p-0;
            & > * {
                @apply flex items-center text-center;
            }
        }
        &.have-value {
            .value-container {
                .select-placeholder,
                .icon-wrapper {
                    @apply bg-primary;
                }
            }
            .select-placeholder {
                border-right: 1px solid #efefef;
            }
        }
    }
}

@primary-color: #48E4B7;