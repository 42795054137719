.drone-dashboard {
    @apply flex flex-col h-full p-4;
    .dashboard-section {
        @apply flex flex-col;
        &.drones-table-wrapper {
            @apply flex-1;
            .drones-list-table {
                table {
                    tbody {
                        height: calc(100vh - 252px);
                    }
                }
            }
        }
    }
}

@primary-color: #48E4B7;